import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variants: Record<string, SystemStyleFunction> = {
  orderDetailModal() {
    return {
      dialog: {
        maxW: 'calc(100vw - 70px)',
        minH: '600px',
      },
    };
  },
  createOrderModal() {
    return {
      dialog: {
        maxW: 'calc(100vw - 20px)',
        minH: '725px',
      },
    };
  },
  importModal() {
    return {
      dialog: {
        maxW: 'calc(100vw - 500px)',
        minH: '400px',
      },
    };
  },
};

export const Modal = {
  variants,
};
